import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'
import Config from '../../config'

export default function HopForHope () {
  const shareTitle = 'Trexity partners with the Shepherds of Good Hope to make Easter a little less stressful'
  const articleLink = 'https://www.sghottawa.com/hopforhope/'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Trexity partners with the Shepherds of Good Hope to make Easter a little less stressful</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>March 9, 2022</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <a href={articleLink} target="_blank" rel="noreferrer"><img src="/img/press/hop-for-hope.jpg" alt="Hop for hope graphic" /></a>
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">Shepherds of Good Hope</a></div>
                <p>We have partnered with Chef Pat Riggins from Riggins Family BBQ who will prepare a gourmet ham dinner for six delivered right to your door. For $150.00, you will enjoy maple-cured applewood smoked ham, cheesy scalloped potatoes, honey roasted local root vegetables, and a gala apple crisp. It all comes with our easy instruction guide to re-heat your feast. Deliveries will be made on Friday April 15th by our delivery partner Trexity. We are very thankful and excited for their partnership.</p>
                <a href={articleLink} target="_blank" rel="noreferrer">Read more at Shepherds of Good Hope</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/serving-up-customer-loyalty" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Serving up customer loyalty with subscriptions and rapid delivery.</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
